import Carousel from "react-bootstrap/Carousel";
import React, { useEffect, useState } from 'react'
import { Endpoint } from "../../Events/Endpoint";
import { Client } from "../../api/Client";
import { isEmptyArray } from "../../Utility/Utility";

function HomeSlider() {
  const [sliderData, setSlider] = useState([]);

  useEffect(() => {
    getSlider(); 
  }, []);

  function getSlider() {
    const url = Endpoint.HOME_SLIDER_LIST;
    Client.getWithLoader(
      url,
      (response) => {
        /* console.log("response", response.data.data); */

        if (!isEmptyArray(response.data)) {
          if (response.data.status) {
            setSlider(response.data.data);
          }
        }
      },
      (error) => {
        setSlider([]);
      }
    );
  }


  return (
    <>
    {!isEmptyArray(sliderData) && (
    <div id="demo" className="carousel slide" data-ride="carousel">
        <Carousel
          nextLabel={false}
          prevLabel={false}
          prevIcon={
            <span aria-hidden="true" className="carousel-control-prev-icon" />
          }
          nextIcon={<span className="carousel-control-next-icon" />}
          indicators={false}
        >

          {sliderData.map((data, key) => (

              <Carousel.Item key={key} >

              
                {(data.image)
                ?

                (<img
                  src={process.env.REACT_APP_IMAGE_URL+'/'+data.image}
                  alt={data.title}
                  height={"500"}
                />) 

                :

                (<video width="100%" loop autoPlay controls allowFullScreen>
                  <source
                  src={data.video_url}
                  type="video/mp4"
                  />
                </video>) 

                }
                
                <div className="carousel-caption">
                  <h3>{data.heading_1}</h3>
                  <p>{data.heading_2}</p>
                </div>


              </Carousel.Item>

          ))} 
               

        </Carousel>
      </div>
      )}

    </>
  )
}
export default HomeSlider;