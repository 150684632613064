import Isotope from 'isotope-layout';
import React, { useEffect, useRef, useState } from 'react'
import { Client } from '../../api/Client';
import { Endpoint } from '../../Events/Endpoint';
import { isEmptyArray } from '../../Utility/Utility';

function GalleryListing() {

    const gridRef = useRef(null);
    const isotope = useRef();
    const [filterKey, setFilterKey] =useState('*');

	 const [categoryData, setCategory] = useState([]);
	 const [galleryData, setGallery] = useState([]);

    /* useEffect(() => {
      isotope.current = new Isotope(gridRef.current, {
        itemSelector: '.item',
        layoutMode: 'fitRows',
      });   

	  setTimeout(() => { 
		isotope.current.arrange({filter: `*`});  
	  }, 500);

 


      return () => isotope.current.destroy();  
    }, []) */


	useEffect(() => {

      getCategory();
	  getGallery(); 
     

      setIsotop();

      setTimeout(() => { 
        setIsotop();
      }, 2800);  
       
   }, []) 


  
     
    useEffect(() => { 
      filterKey === '*'
        ? isotope.current.arrange({filter: `*`})
        : isotope.current.arrange({filter: `${filterKey}`}); 

    }, [filterKey])
  
    const handleFilterKeyChange = key => () => setFilterKey(key);


	function setIsotop() {

		isotope.current = new Isotope(gridRef.current, {
			itemSelector: '.item',
			layoutMode: 'fitRows',
		  });   
	
		  setTimeout(() => { 
			isotope.current.arrange({filter: `*`});  
		  }, 500);  
		  return () => isotope.current.destroy();   
	  }

	  function getCategory() {
		const url = Endpoint.GALLERY_CATEGORY_LIST;;
		Client.getWithLoader(
		  url,
		  (response) => { 
			if (!isEmptyArray(response.data)) {
			  if (response.data.status) {
				setCategory(response.data.data);
			  }
			}
		  },
		  (error) => {
			setCategory([]);
		  }
		);
	  }

	  function getGallery() {
		const url = Endpoint.GALLERY_LIST;;
		Client.getWithLoader(
		  url,
		  (response) => { 
			if (!isEmptyArray(response.data)) {
			  if (response.data.status) {
				setGallery(response.data.data);
			  }
			}
		  },
		  (error) => {
			setGallery([]);
		  }
		);
	  }

	  

  return (
    <>
    
    
    <div className="container">
	<div className="spacer-single"></div>

	 
	<div className="row">
	  <div className="col-md-12 text-center">
		<ul id="filters" className="wow fadeInUp" data-wow-delay="0s">
 

        <li><a className={(filterKey=='*') ? 'selected cursor-pointer': 'cursor-pointer'} onClick={handleFilterKeyChange('*')} >All Gallery</a></li>

        {/* <li><a className={(filterKey=='.residential') ? 'selected' : ''}   onClick={handleFilterKeyChange('.residential')} >Residential</a></li>
        <li><a className={(filterKey=='.hospitaly') ? 'selected' : ''} onClick={handleFilterKeyChange('.hospitaly')}  >Hospitaly</a></li>
        <li><a className={(filterKey=='.office') ? 'selected' : ''} onClick={handleFilterKeyChange('.office')} >Office</a></li>
        <li><a className={(filterKey=='.commercial') ? 'selected' : ''} onClick={handleFilterKeyChange('.commercial')}  >Commercial</a></li>
		 */}

		{!isEmptyArray(categoryData) &&
		categoryData.map((data, key) => (

		<li key={key}>
			<a className={(filterKey==`.cat_${data.id}`) ? 'selected cursor-pointer' : 'cursor-pointer'} onClick={handleFilterKeyChange(`.cat_${data.id}`)}  >
			{data.title}
			</a> 
		</li>

		))} 
		</ul>
	  </div>
	</div>
	 
  </div>



    <div
     ref={gridRef}
    id="gallery"
    className="gallery full-gallery de-gallery pf_4_cols wow fadeInUp"
    data-wow-delay=".3s"
    >


		{!isEmptyArray(galleryData) && ( 
		galleryData.map((data, key) => (

			<div key={key} className={"col-md-4 item "+(data.filter_class)} >
				<div className="picframe">
				<div className="holder">
					<img
					src={process.env.REACT_APP_IMAGE_URL + "/" + data.image}
					/>
				</div>
				</div>
			</div>

			)) 
		)}
		
    </div>
    
    
    </>
  )
}
export default GalleryListing;