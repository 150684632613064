import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { Client } from '../../api/Client';
import { Endpoint } from '../../Events/Endpoint';
import { isEmptyArray } from '../../Utility/Utility';

function Testimonials() {

    const [testimonialData, setTestimonial] = useState([]);
    const [testimonialChunkData, setChunkTestimonial] = useState([]);

    useEffect(() => {
      getTestimonial(); 
    }, []);
  
    function getTestimonial() {
      const url = Endpoint.TESTIMONIAL_LIST;
      Client.getWithLoader(
        url,
        (response) => {
          /* console.log("response ", response.data.data); */
          if (!isEmptyArray(response.data)) {
            if (response.data.status) {
              /* console.log("setTestimonial ", response.data.data); */
              setTestimonial(response.data.data);
              getAarray(response.data.data);

            }
          }
        },
        (error) => {
            setTestimonial([]);
            setChunkTestimonial([]);
        }
      );
    }

    function getAarray(arrayData){

      /* console.log("arrayData ", arrayData); */

      const perChunk = 2 // items per chunk    

      /* const inputArray = ['a','b','c','d','e']; */

      const inputArray = arrayData;
      
      const result = inputArray.reduce((resultArray, item, index) => { 
        const chunkIndex = Math.floor(index/perChunk)
      
        if(!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }
      
        resultArray[chunkIndex].push(item)

        /* console.log("resultArray = ",resultArray); */

        setChunkTestimonial(resultArray);
      
        return resultArray
      }, []);

    }

  return (
    <>
    {!isEmptyArray(testimonialChunkData) && (
    <section id="section-testimonial" className="text-light">
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-3 text-center wow fadeInUp">
                <h1>Customer Says</h1>
                <div className="separator">
                  <span><i className="fa fa-circle"></i></span>
                </div>
                <div className="spacer-single"></div>
              </div>
            </div>
           

<Carousel
          items={2}  
          nextLabel={false}
          prevLabel={false}
          prevIcon={false}
          nextIcon={false}
          indicators={true}
          indicatorLabels={[<button role="button" className="owl-dot " />]}
          
          id="testimonial-carousel"
        >

{testimonialChunkData.map((ChunkData, key) => (

<Carousel.Item key={key} > 

        <div className="row">
         {ChunkData.map((data, key_inner) => (
            <div className="col-sm-6 item" key={key_inner}>
            <div className="de_testi">
                <blockquote>
                <p>
                {data.description}
                </p>
                <div className="de_testi_by">{data.title}{(data.designation) && (", "+data.designation) }</div>
                </blockquote>
            </div>
            </div> 
             ))}  

        </div> 

    </Carousel.Item>
 ))} 
 

</Carousel>


               


             
          </div>
        </section>
    )}
    </>
  )
}
export default Testimonials;