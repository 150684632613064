import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
/* 
import 'bootstrap/dist/css/bootstrap.css';
import './assets/stylesheet/main.css';
*/
import './assets/css/bootstrap.css';
import './assets/css/jpreloader.css';
import './assets/css/animate.css';
import './assets/css/plugin.css';
import './assets/css/owl.carousel.css';
import './assets/css/owl.theme.css';
import './assets/css/owl.transitions.css';
import './assets/css/magnific-popup.css';
import './assets/css/style.css';

import './assets/css/style1.css';
import './assets/css/bg.css';
import './assets/css/color.css';
import './assets/fonts/font-awesome/css/font-awesome.css';
import './assets/fonts/elegant_font/HTML_CSS/style.css';
import './assets/fonts/et-line-font/style.css'; 
import './assets/rs-plugin/css/settings.css';
import './assets/css/rev-settings.css'; 
import "./App.css";

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
 
import { Provider } from 'react-redux';
import AlertMessage from './components/global/AlertMessage';
import LoaderFrame from './components/global/LoaderFrame';
import store from './Features/store';


const root = ReactDOM.createRoot(document.getElementById('root'));

let persistor = persistStore(store)

root.render(
  <Provider store={store}>
  <PersistGate persistor={persistor}>
  <React.StrictMode>
    <App />
    <LoaderFrame/>
    <AlertMessage/>
  </React.StrictMode>
  </PersistGate>
  </Provider>
);


/* root.render(
  <React.StrictMode>
    <App />
    <LoaderFrame/>
  </React.StrictMode>
);
 */

reportWebVitals();
