import React from "react";
import { Link } from "react-router-dom";
 

export default function NotFound() {
  return (
    <>
       
       <div className="page-about">

       <section id="subheader"   style={{backgroundImage : 'https://accademy.impulsion.mu/html/assets/images/background/subheader-3.jpg'}} >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>404</h1>
                <ul className="crumb">
                  <li><Link to="/" >Home</Link></li>
                  <li className="sep">/</li>
                  <li>404</li>
                </ul>
              </div>
            </div>
          </div>
        </section> 

      <div className="wrapper mt-4 mb-40">
        <div className="container">
          <div className='text-center'>
            
          <h2 >Ooops! Sorry, Page Not Found</h2>

          <br />

          <Link className="btn btn-dark btn-lg " to="/">  Back home  </Link>
                
            
          </div>
        </div>
      </div>
      
      </div>

    </>
  );
}
